<template>
  <div style="width:100%;height: calc(100vh - 90px);position: relative" class="card">
    <div id="map1" style="width:100%;height: 100%"></div>
    <div class="inputs">
      <button @click="end">技术</button>
    </div>
  </div>


</template>

<script>
export default {
  name: "test",
  data() {
    return {
      map: null,
      path: [],
      mouseTool: null,
      polyEditor: null,
      polygon: null,
    }
  },
  mounted() {
    this.initMap()
    this.show()
    this.update()
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        center: [116.400274, 39.905812],
        layers: [
          new AMap.TileLayer.RoadNet(),
          new AMap.TileLayer.Satellite()
        ],
        zoom: 5
      });
    },
    init() {
      let _this = this

      this.mouseTool = new AMap.MouseTool(this.map);
      this.mouseTool.on('draw', function (e) {
        console.log(e.obj.De.path)
        _this.path = e.obj.De.path
      })

      this.mouseTool.polygon({
        path: this.overlays,
        fillColor: '#00b0ff',
        strokeColor: '#80d8ff'
      })

    },
    show() {
      setTimeout(() => {
        this.path = [
          [110.6873833756149, 37.78403832935583],
          [110.9510552506149, 33.64694964942028],
          [116.2684380631149, 37.05109428693477],
        ]
        this.polygon = new AMap.Polygon({
          path: this.path,
          strokeColor: "#FF33FF",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50,
        })
        this.map.add(this.polygon)
        // 缩放地图到合适的视野级别
        this.map.setFitView([this.polygon])
      }, 2000)
    },
    update() {
      let _this = this

      setTimeout(() => {
        _this.polyEditor = new AMap.PolyEditor(_this.map, _this.polygon)
        _this.polyEditor.open()

        _this.polyEditor.on('end', function (event) {
          console.log(event)

          // console.log(_this.path)
          // let area = Math.round(AMap.GeometryUtil.ringArea(_this.path));
          // let text = new AMap.Text({
          //   position: new AMap.LngLat(_this.path[0].R, _this.path[0].Q),
          //   text: '区域面积' + area + '平方米',
          //   offset: new AMap.Pixel(-20, -20)
          // })
          // _this.map.add(text);
          // _this.map.setFitView();
        })
      }, 5000)

    },
    end() {
      this.polyEditor.close()
    }
  }
}
</script>

<style scoped>
.inputs {
  position: absolute;
  width: 80px;
  height: 50px;
  top: 2rem;
  left: 2rem;
  background: #ffffff;
  padding: 10px;
}
</style>